import styled, { css } from 'styled-components';
import React from 'react';
import { H4, SectionText, SectionWrapper } from 'styles/Typography.styled';
import { mediaQueries } from 'shared/breakpoints';
import PolandFlag from 'assets/images/_logos/flagPoland.png';
import LogoGov from 'assets/images/_logos/logoGov.png';
import NCBR from 'assets/images/_logos/logoNCBR.svg';
import * as Common from 'components/_universal/Common';

const LogoWrapper = styled(SectionWrapper)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0;
`;

const Image = styled.img`
    max-height: 80px;

    ${mediaQueries.xs} {
        max-height: 40px;
    }
`;

const Wrapper = styled(SectionWrapper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 200px 20px 100px;

    ${mediaQueries.ipadH} {
        padding: 150px 40px 90px;
    }
    ${mediaQueries.sm} {
        padding: 170px 20px 50px;
    }
`;

const Title = styled(H4)(
    ({ theme: { fontSizes } }) => css`
        line-height: 18px;

        ${mediaQueries.sm} {
            font-size: ${fontSizes.f18};
            line-height: 24px;
            text-align: center;
        }
        ${mediaQueries.xs} {
            margin: 20px 0;
        }
    `,
);

const Divider = styled.div(
    ({ theme: { colors } }) =>
        css`
            background-color: ${colors.border};
            height: 1px;
            margin: 30px 0;
            width: 100%;

            ${mediaQueries.xs} {
                display: none;
            }
        `,
);

export default () => {
    return (
        <Wrapper>
            <LogoWrapper>
                <Common.Div flex="row" gap="16px">
                    <Image src={PolandFlag} />
                    <Image src={LogoGov} />
                </Common.Div>
                <Image src={NCBR} />
            </LogoWrapper>
            <SectionText>
                Projekt pt. „Inteligentny asystent lekarza” realizowany w ramach
                konkursu IV konkursu Strategicznego Programu Badań Naukowych i
                Prac Rozwojowych pod nazwą „Zaawansowane technologie
                informacyjne, telekomunikacyjne i mechatroniczne INFOSTRATEG”.
                <br />
                <br />
                Projekt realizowany jest samodzielnie przez spółkę Codahead Sp.
                z o.o. Sp. k.
                <br />
                <br />
                Przedmiotem projektu jest przeprowadzenie interdyscyplinarnych
                prac badawczo-rozwojowych oraz przedwdrożeniowych w sektorze
                ICT, mających na celu stworzenie innowacyjnego w skali kraju
                rozwiązania technologicznego. Będzie to inteligentny system
                przetwarzania mowy skierowany dla branży medycznej. Opracowane
                narzędzie zezwoli na automatyzację wywiadów lekarskich na
                podstawie nagrania dialogu pacjent-lekarz. Umożliwidokładne
                przetwarzanie mowy, a jednocześniewydobywanie z zarejestrowanych
                wypowiedzi istotnych informacji, które przesyłane będą do
                zbudowanego formularza i bazy danych (system
                pytanie-odpowiedź).Wartość projektu: 7 181 000,00 PLN
                <br />
                <br />
                Wartość dofinansowania: 5 211 435,00 PLN
                <br />
                <br />
                Okres realizacji projektu: luty 2023 r. – styczeń 2026 r.
            </SectionText>
            <Divider />
            <Title>
                Codahead sp. z o.o. sp. k. realizuje projekt dofinansowany z
                Funduszy Europejskich:
            </Title>
            <Divider />
            <SectionText>
                Opracowanie szyfrowanego czytnika OBDII z przechowywaniem danych
                w sieci blockchain tworzącej wiarygodne informacje o cyklu życia
                pojazdów mechanicznych.” Celem projektu jest przeprowadzenie
                prac B+R w celu opracowania innowacji produktowej o charakterze
                światowym. W ramach realizowanych badań Wnioskodawca opracuje
                rozwiązanie – zupełnie autonomiczny system, wykorzystujący
                czytnik złącza OBD do pobierania danych z komputera pokładowego
                pojazdu i przesyłający w sposób bezpieczny zebrane informacje do
                autorskiej sieci blockchain. Tworząc tym samym
                zdecentralizowaną, rozproszoną bazę danych, zawierającą w pełni
                autentyczne, niemożliwe do zmodyfikowania informacje o historii
                pojazdów.
                <br />
                <br />
                Koszty ogółem: 4 222 296,00 PLN
                <br />
                Dofinansowanie projektu z UE: 2 709 372,00 PLN
            </SectionText>
        </Wrapper>
    );
};
